html,
body {
    font-family: 'Microsoft Yahei', 'Helvetica Neue', Helvetica, Arial, sans-serif;
    font-size: 28px;
    color: #666;
}

body,
div,
ul,
li,
ol,
h1,
h2,
h3,
h4,
h5,
h6,
input,
textarea,
select,
p,
dl,
dt,
dd,
a,
img,
button,
form,
table,
th,
tr,
td,
tbody,
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
    margin: 0;
    padding: 0;
    border: none;
    /* -webkit-font-smoothing: antialiased; */
    /* font-smoothing: antialiased; */
}


/* 去除虚线 */

input:focus,
select:focus,
textarea:focus,
button:focus {
    outline: none;
}


/*重置部分表单圆角和内阴影*/

input[type='text'],
input[type='file'],
input[type='password'],
input[type='search'],
input[type='tel'],
input[type='number'],
input[type='email'],
input[type='url'],
textarea {
    -webkit-appearance: none;
}

a {
    text-decoration: none;
}

html,
body {
    background: #fff;
    height: 100%;
}

li {
    list-style: none;
}

::-webkit-scrollbar {
    width: 0px;
    height: 0px;
    background-color: #f5f5f5;
}


/* 滚动条 */


/* 滚动条样式设置 */

::-webkit-scrollbar-thumb {
    background-color: #f5f5f5;
    border-radius: 5px;
}

::-webkit-scrollbar {
    width: 8px;
    height: 8px;
}

.ellipsis {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.tc {
    text-align: center;
}

.tl {
    text-align: left;
}

.mt-50 {
    margin-top: 50px;
}

.am-activity-indicator-content {
    padding: 30px 40px;
    font-size: 20px;
}

.am-activity-indicator-spinner-lg {
    width: 48px;
    height: 48px;
}